import noUiSlider from 'nouislider'
import { Controller } from '@hotwired/stimulus'
import { setVolume } from '../lib/volume'
import { getPlayerSetting, setPlayerSetting } from '../lib/player_settings'
import wave from '../lib/wave'
import Mousetrap from 'mousetrap'
import { get } from 'lodash'
import { post } from '@rails/request.js'
import { UAParser } from 'ua-parser-js'

const isMuted = () => getPlayerSetting('muted') === 'true'

const getVolumeFromCookie = () => {
  const storedVolume = getPlayerSetting('volume')

  return storedVolume === null ? 1 : parseFloat(storedVolume)
}

const displayTime = (time) => {
  const roundedTime = Math.floor(time)
  const minutes = Math.floor(roundedTime / 60)
  const seconds = Math.floor(roundedTime % 60)
  const secondsOnesPlace = seconds % 10
  const secondsTensPlace = (seconds - secondsOnesPlace) / 10

  return `${minutes}:${secondsTensPlace}${secondsOnesPlace}`
}

export default class extends Controller {
  static targets = [
    'head',
    'duration',
    'currentTime',
    'mute',
    'next',
    'prev',
    'repeat',
    'seventyFive',
    'thirtyThree',
    'volume',
    'waveform',
    'zero',
  ]

  initialize() {
    Mousetrap.bind('1', () => this.cueZero())
    Mousetrap.bind('2', () => this.cueThirtyThree())
    Mousetrap.bind('3', () => this.cueSeventyFive())
    Mousetrap.bind('r', () => this.toggleRepeat())
    Mousetrap.bind('[', () => this.scrub(-0.1))
    Mousetrap.bind(']', () => this.scrub(0.1))

    this.element[this.identifier] = this
    this.element.controller = this

    this.handlePlayPause = this.handlePlayPause.bind(this)
    this.toggleMute = this.toggleMute.bind(this)
    this.repeatRecording = this.repeatRecording.bind(this)
    this.listenForKeyboardEvents = this.listenForKeyboardEvents.bind(this)
    this.onAudioReady = this.onAudioReady.bind(this)
    this.onAudioProcess = this.onAudioProcess.bind(this)
    this.onAudioFinish = this.onAudioFinish.bind(this)
    this.onAudioPause = this.onAudioPause.bind(this)
    this.onAudioPlay = this.onAudioPlay.bind(this)
    this.onAudioError = this.onAudioError.bind(this)
    this.repeating = false
    this.isLoading = false

    const ua = UAParser(navigator.userAgent)
    const device = ua.device
    this.isMobile = device.type !== undefined
  }

  scrub(n) {
    if (typeof n !== 'number' || !this.wave.isPlaying()) return

    const playedPercentage =
      this.wave.getCurrentTime() / this.wave.getDuration()

    const scrubTime = Math.min(Math.max(0, playedPercentage + n), 1)
    this.wave.seekTo(scrubTime)
  }

  unPressCues() {
    this.zeroTarget.classList.remove('is-active')
    this.thirtyThreeTarget.classList.remove('is-active')
    this.seventyFiveTarget.classList.remove('is-active')
  }

  cueZero() {
    this.unPressCues()
    this.zeroTarget.classList.add('is-active')
    this.zeroTarget.setAttribute('aria-pressed', true)
    this.data.set('seek-to', 0)

    this.wave.seekTo(0)
  }
  cueThirtyThree() {
    this.unPressCues()
    this.thirtyThreeTarget.classList.add('is-active')
    this.thirtyThreeTarget.setAttribute('aria-pressed', true)
    this.data.set('seek-to', 0.33)
    this.wave.seekTo(0.33)
  }

  cueSeventyFive() {
    this.unPressCues()
    this.seventyFiveTarget.classList.add('is-active')
    this.seventyFiveTarget.setAttribute('aria-pressed', true)
    this.data.set('seek-to', 0.75)
    this.wave.seekTo(0.75)
  }

  playNext() {
    const event = new Event('click')
    if (
      this.activePlayerItem.element ===
      $('[data-controller="player-item"]').eq(-2).get(0)
    ) {
      const infiniteScroll =
        this.application.getControllerForElementAndIdentifier(
          $('[data-controller="infinite-scroll"]').get(0),
          'infinite-scroll'
        )
      if (infiniteScroll) {
        infiniteScroll.loadMore()
      }
    }
    this.activePlayerItem.nextTrackController.play(event)
  }

  playPause() {
    this.wave.playPause()
  }

  playPrevious() {
    const event = new Event('click')
    this.activePlayerItem.prevTrackController.play(event)
  }

  repeatRecording() {
    this.element.classList.add('is-playing')
    this.wave.play(
      parseFloat(this.data.get('seek-to')) * this.wave.getDuration()
    )
  }

  toggleMute(e) {
    e.preventDefault()
    const isMuted = this.muteTarget.getAttribute('aria-pressed') == 'true'

    if (isMuted) {
      setVolume(this.wave, getVolumeFromCookie(), () => {
        setPlayerSetting('muted', false)
      })
      this.muteTarget.setAttribute('aria-pressed', false)
      this.muteTarget.classList.remove('is-active')
    } else {
      setVolume(this.wave, 0, () => {
        setPlayerSetting('muted', true)
      })
      this.muteTarget.setAttribute('aria-pressed', true)
      this.muteTarget.classList.add('is-active')
    }
  }

  toggleRepeat() {
    this.repeating = !this.repeating

    if (this.repeating) {
      this.repeatTarget.classList.add('is-active')
      this.repeatTarget.setAttribute('aria-pressed', true)
      this.wave.un('finish', this.onAudioFinish)
      this.wave.on('finish', this.repeatRecording)
    } else {
      this.repeatTarget.classList.remove('is-active')
      this.repeatTarget.setAttribute('aria-pressed', false)
      this.wave.un('finish', this.repeatRecording)
      this.wave.on('finish', this.onAudioFinish)
    }
  }

  play(pos = 0) {
    document.dispatchEvent(new CustomEvent('player:is-playing'))
    this.wave.setVolume(0)

    // This seems like a bug in wavesurfer where a pos of zero on
    if (pos === 0 || isNaN(pos)) {
      // initialzation does not work. So we set it to null, which then
      // forces wavesurfer.js to use defaults
      pos = null
    }
    this.wave.play(pos)

    if (!isMuted()) {
      const targetVolume = getVolumeFromCookie()
      setVolume(this.wave, targetVolume)
    }
  }

  pause() {
    document.dispatchEvent(new CustomEvent('player:is-pausing'))

    setVolume(this.wave, 0, () => {
      this.wave.pause()
    })
  }

  handlePlayerItemDisconnect() {
    this.nextTarget.disabled = true
    this.prevTarget.disabled = true
    this.activePlayerItem = null
  }

  headTargetConnected() {
    if (this.playerReadyToPlay) {
      this.playerReadyToPlay()
    }
  }

  handlePlayPause(e) {
    const { controller: playerItem } = e.detail

    if (this.activePlayerItem != playerItem) {
      this.nextTarget.disabled = true
      this.prevTarget.disabled = true

      if (get(this, 'activePlayerItem.element')) {
        this.activePlayerItem.element.classList.remove('pause')
        this.activePlayerItem.element.classList.remove('active')
      }

      this.startLoadingAnimation()

      if (!!this.playerReadyToPlay) {
        this.cancelReadyToPlay()
      }
      this.playerReadyToPlay,
        this.cancelReadyToPlay,
        (this.afterReadyToPlay = new Promise((r, c) => {
          this.playerReadyToPlay = r
          this.cancelReadyToPlay = c
        }))
      this.afterReadyToPlay.then(() => {
        this.element.classList.remove('is-empty')

        const { peaks, audioUrl, duration } = this.headTarget.dataset

        this.wave.load(
          audioUrl,
          JSON.parse(peaks) || [],
          this.isMobile ? 'metadata' : 'auto',
          parseInt(duration)
        )

        if (playerItem.hasNextTrack) {
          this.nextTarget.disabled = false
        }

        if (playerItem.hasPrevTrack) {
          this.prevTarget.disabled = false
        }
      })

      post(playerItem.playUrl, { responseKind: 'turbo-stream' })
      this.activePlayerItem && this.activePlayerItem.removePlayer()
      playerItem.setPlayer(this)
      this.activePlayerItem = playerItem
    } else {
      this.playPause()
    }
  }

  listenForKeyboardEvents(e) {
    if (
      document.activeElement.tagName === 'INPUT' ||
      document.activeElement.tagName === 'TEXTAREA'
    )
      return
    switch (e.keyCode) {
      case 32:
        e.preventDefault()
        this.playPause()
        break
      case 37:
      case 38:
        e.preventDefault()
        if (!this.prevTarget.getAttribute('disabled')) {
          this.playPrevious()
        }
        break
      case 39:
      case 40:
        e.preventDefault()
        if (!this.nextTarget.getAttribute('disabled')) {
          this.playNext()
        }
        break
    }
  }

  onAudioProcess() {
    this.currentTimeTarget.textContent = displayTime(this.wave.getCurrentTime())
  }

  onAudioReady() {
    this.stopLoadingAnimation()
    const duration = this.wave.getDuration()
    const startPos = parseFloat(this.data.get('seek-to')) * duration
    this.durationTarget.textContent = displayTime(duration)

    if (!this.isMobile) {
      this.play(startPos)
      document.addEventListener('keydown', this.listenForKeyboardEvents)
    }
  }

  onAudioFinish() {
    this.element.classList.remove('is-playing')
    this.playNext()
  }

  onAudioError() {
    this.wave.stop()
    this.wave.empty()
    this.element.classList.add('is-empty')
    this.element.classList.remove('is-playing')
    this.stopLoadingAnimation()
  }

  onAudioPause() {
    this.element.classList.remove('is-playing')
    if (get(this, 'activePlayerItem.element')) {
      this.activePlayerItem.element.classList.remove('active')
      this.activePlayerItem.element.classList.add('pause')
    }
  }

  onAudioPlay() {
    this.element.classList.add('is-playing')
    if (get(this, 'activePlayerItem.element')) {
      this.activePlayerItem.element.classList.remove('pause')
      this.activePlayerItem.element.classList.add('active')
    }
  }

  startLoadingAnimation() {
    this.isLoading = true
    this.showLoadingAnimation = setTimeout(
      () => {
        this.element.classList.add('is-loading')
      },
      this.element.classList.contains('is-empty') ? 0 : 200
    )
  }

  stopLoadingAnimation() {
    this.isLoading = false
    if (this.showLoadingAnimation) {
      clearTimeout(this.showLoadingAnimation)
    }
    this.element.classList.remove('is-loading')
  }

  connect() {
    document.addEventListener(
      `${this.identifier}:play-pause`,
      this.handlePlayPause
    )

    this.wave ||= wave(this.waveformTarget)
    window.wave = this.wave
    this.wave.on('audioprocess', this.onAudioProcess)

    if (!this.repeating) {
      this.wave.on('finish', this.onAudioFinish)
    }

    this.wave.on('ready', this.onAudioReady)
    this.wave.on('error', this.onAudioError)
    this.wave.on('play', this.onAudioPlay)
    this.wave.on('pause', this.onAudioPause)

    this.volumeSlider = noUiSlider.create(this.volumeTarget, {
      start: [getVolumeFromCookie()],
      connect: [true, false],
      range: {
        min: 0,
        max: 1,
      },
    })

    this.volumeSlider.on('slide', (values) => {
      const targetVolume = +values[0]
      setVolume(this.wave, targetVolume)
      setPlayerSetting('volume', targetVolume)
    })

    this.volumeSlider.on('change', (values) => {
      const targetVolume = +values[0]
      setVolume(this.wave, targetVolume)
      setPlayerSetting('volume', targetVolume)
    })

    if (isMuted()) {
      this.muteTarget.setAttribute('aria-pressed', true)
      this.muteTarget.classList.add('is-active')
    } else {
      this.muteTarget.setAttribute('aria-pressed', false)
      const targetVolume = getVolumeFromCookie()
      setVolume(this.wave, targetVolume)
    }
  }

  disconnect() {
    // This is empty because the player will ALWAYS show
    // but it hidden from sight via @needs_player
    // document.removeEventListener(`${this.identifier}:play-pause`, this.handlePlayPause)
    document.removeEventListener('keydown', this.listenForKeyboardEvents)
    this.volumeSlider.destroy()
    this.wave.unAll()
  }
}
