import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['downloadLink', 'addProject', 'removeProject']
  static values = {
    recordingId: Number
  }

  openMenu() {
    const userData = document.querySelector('#user_data')['user-data']

    this.updateDownloadOption(userData)
    this.updateProjectOption(userData)
  }

  updateDownloadOption(userData) {
    if (userData.userDownloadedRecordings.includes(this.recordingIdValue)) {
      return this.showDownloadAgain()
    }

    if (userData.userRemainingDownloads > 0) {
      return this.updateRemainingDownloads(userData.userRemainingDownloads)
    }

    if (userData.userAllowedToRequestMoreDownloads) {
      this.showRequestMoreLink()
    } else {
      this.showDownloadsRequested()
    }
  }

  updateProjectOption(userData) {
    if (!userData.activeProjectEditable) {
      return this.hideProjectOption()
    }

    if (userData.activeProjectRecordings.includes(this.recordingIdValue)) {
      this.showRemoveFromProject()
    } else {
      this.showAddToProject()
    }
  }

  showDownloadAgain() {
    this.downloadLinkTarget.innerHTML = `Download again`
  }

  updateRemainingDownloads(remainingDownloads) {
    this.downloadLinkTarget.innerHTML = `Download (${remainingDownloads} remaining)`
  }

  showDownloadsRequested() {
    this.downloadLinkTarget.setAttribute('href', '#')
    this.downloadLinkTarget.classList.add('disabled')
    this.downloadLinkTarget.innerHTML = `Downloads requested`
  }

  showRequestMoreLink() {
    const downloadLink = document.createElement('a')
    downloadLink.setAttribute('data-turbo-frame', 'modal-container')
    downloadLink.setAttribute('data-recordings-menu-data-target', 'downloadLink')
    downloadLink.setAttribute('data-action', 'dropdown-menu#toggle')
    downloadLink.setAttribute('class', 'dropdown-item')
    downloadLink.setAttribute('href', `/compositions/${this.recordingIdValue}/open_request_more_downloads_modal`)
    downloadLink.innerHTML = `Ask for more downloads`
    this.downloadLinkTarget.parentNode.appendChild(downloadLink)
    this.downloadLinkTarget.remove()
  }

  showAddToProject() {
    this.removeProjectTarget.classList.add('is-hidden')
    this.addProjectTarget.classList.remove('is-hidden')
  }

  showRemoveFromProject() {
    this.removeProjectTarget.classList.remove('is-hidden')
    this.addProjectTarget.classList.add('is-hidden')
  }

  hideProjectOption() {
    this.addProjectTarget.classList.add('is-hidden')
    this.removeProjectTarget.classList.add('is-hidden')
  }
}
