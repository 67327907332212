import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.element[this.identifier] = this
    this.element.controller = this
  }

  play(event) {
    event.preventDefault()

    const playerEvent = new CustomEvent("player:play-pause", {
      detail: {
        target: this.element,
        controller: this,
      },
    })

    document.dispatchEvent(playerEvent)
  }

  get playUrl() {
    return this.element.dataset.playUrl
  }

  get recordingId() {
    return this.element.dataset.recordingId
  }

  get nextTrack() {
    if (this.disconnected) { return; }

    if (this.element.parentElement.nodeName === 'TD') {
      const parentRow = this.element.closest('tr')
      if (!!parentRow.nextElementSibling) {
        return parentRow.nextElementSibling.querySelector('[data-controller="player-item"]')
      } else {
        return
      }
    } else {
      return this.element.nextElementSibling
    }
  }

  get prevTrack() {
    if (this.disconnected) { return; }

    if (this.element.parentElement.nodeName === 'TD') {
      const parentRow = this.element.closest('tr')
      if (!!parentRow.previousElementSibling) {
        return parentRow.previousElementSibling.querySelector('[data-controller="player-item"]')
      } else {
        return
      }
    } else {
      return this.element.previousElementSibling
    }
  }

  get hasNextTrack() {
    if (this.disconnected) {
      return false
    } else {
      return !!this.nextTrack
    }
  }

  get hasPrevTrack() {
    if (this.disconnected) {
      return false
    } else {
      return !!this.prevTrack
    }
  }

  get nextTrackController() {
    return this.nextTrack.controller
  }

  get prevTrackController() {
    return this.prevTrack.controller
  }

  setPlayer(player) {
    this.player = player
  }

  removePlayer() {
    this.player = null
  }

  disconnect() {
    this.disconnected = true
    this.player && this.player.handlePlayerItemDisconnect()
  }

  connect() {
    this.disconnected = false
  }
}
